import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Button } from '../components/patterns/button';

import logoBandcamp from '../images/logo-bandcamp.svg';
import logoAppleMusic from '../images/logo-apple-music.svg';

const FuneraryCD = () => (
  <Layout>
    <SEO title="Funerary CD" />
    <div className="container">
      <h1>Funerary CD</h1>
      <img
        src="https://f4.bcbits.com/img/a1457582257_16.jpg"
        alt="Pulling Teeth - Funerary CD"
        width="312"
        height="312"
        decoding="async"
        style={{ marginBottom: '1.5rem' }}
      />
      <p>
        Pulling Teeth&apos;s fourth album is their most definitive work by far.
        Their magnum opus, if you will. The two years that followed the release
        of Paranoid Delusions | Paradise Illusions was a time of personal
        turmoil for members of the band as they lost too many loved ones to the
        hands of the reaper, and Funerary is the culmination of the emotions
        dealt with during that period. This album finds the band revisiting the
        vitriolic rage expressed so vividly on Martyr Immortal as well as
        expanding on some of the more expressive and melancholic ideas found on
        their last album. While the lyrics touch on themes of our social
        character, a demand for truth, respect, and honesty, and reason to hold
        on to the last shreds of hope for a better world, the music ranges from
        pummeling thrash- and death- influenced metal to moody, doomy, dirgey
        soundscapes, all the while holding true to the spirit and energy of
        hardcore. This is Pulling Teeth at their finest.
      </p>

      <div className="ctas-wrapper">
        <Button
          ctaText="Buy the CD"
          ctaUrl="https://firestartertoxicpop.bandcamp.com/album/funerary"
          isSolid
        />
      </div>

      <div className="flex-wrapper flex-wrapper--two">
        <a
          href="https://firestartertoxicpop.bandcamp.com/album/funerary"
          style={{ padding: '1.5rem' }}
        >
          <img
            className="digital-music-logo"
            src={logoBandcamp}
            alt="Bandcamp logo"
            decoding="async"
          />
        </a>
        <a
          href="https://music.apple.com/us/album/funerary/435055840"
          style={{ padding: '1.5rem' }}
        >
          <img
            className="digital-music-logo"
            src={logoAppleMusic}
            alt="Apple Music logo"
            decoding="async"
          />
        </a>
      </div>
    </div>
  </Layout>
);

export default FuneraryCD;
